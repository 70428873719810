import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material.module';
import {NavbarComponent} from './navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {ContactboxComponent} from './contactbox/contactbox.component';


@NgModule({
  declarations: [NavbarComponent, FooterComponent, ContactboxComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    NavbarComponent,
    RouterModule,
    FooterComponent,
    ContactboxComponent,
  ]
})
export class SharedModule {
}
