import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-woodpackaging',
  templateUrl: './woodpackaging.component.html',
  styleUrls: ['./woodpackaging.component.scss']
})
export class WoodpackagingComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {

  }
}
