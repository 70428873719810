import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-contactbox',
  templateUrl: './contactbox.component.html',
  styleUrls: ['./contactbox.component.scss']
})
export class ContactboxComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
