import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './contact/contact.component';
import {AboutusComponent} from './aboutus/aboutus.component';
import {SustainabilityComponent} from './sustainability/sustainability.component';
import {GoodsComponent} from './goods/goods.component';
import {WoodenrichmentComponent} from './woodenrichment/woodenrichment.component';
import {WoodpackagingComponent} from './woodpackaging/woodpackaging.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'sustainability', component: SustainabilityComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'goods', component: GoodsComponent},
  {path: 'woodenrichment', component: WoodenrichmentComponent},
  {path: 'woodpackaging', component: WoodpackagingComponent},


];

@NgModule({
  declarations: [
    ContactComponent,
    AboutusComponent,
    SustainabilityComponent,
    GoodsComponent,
    WoodenrichmentComponent,
    WoodpackagingComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ]
})
export class PagesModule {
}
